import { Anchor, type _Navigation } from ":shared/components/route";
import { default as routes } from "../routes";
import IdleIcon from "../assets/icons/idle-message.svg";
import ActiveIcon from "../assets/icons/active-message.svg";
export default function HelpCenter(props: _Navigation.SideNavJSXItemProps) {
  return (
    <Anchor
      class={props.anchor?.class}
      setup={{
        to: routes,
        classes: {
          active: `${props.anchor.setup.classes.active}`,
        },
      }}
      components={{
        title: "Help Center",
        idleIcon: <IdleIcon />,
        activeIcon: <ActiveIcon />,
      }}
    />
  );
}
